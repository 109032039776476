import { SET_SELECTED_VENDOR, RESET_VENDOR } from '../actions/vendorActions';

const initialVendorState = {
  vendorId: localStorage.getItem('vendorId') || null,
  vendorMobile: localStorage.getItem('vendorMobile') || null,
};

const vendorReducer = (state = initialVendorState, action) => {
  switch (action.type) {
    case SET_SELECTED_VENDOR:
      const { vendorId, vendorMobile } = action.payload;

      // Store both vendorId and mobileNumber in localStorage
      localStorage.setItem('vendorId', vendorId);
      localStorage.setItem('vendorMobile', vendorMobile);
      
      return {
        ...state,
        vendorId,
        vendorMobile,
      };
    case RESET_VENDOR: // Handle the RESET_VENDOR action
      localStorage.removeItem('vendorId'); // Remove vendorId from localStorage
      localStorage.removeItem('vendorMobile');

      return {
        ...state,
        vendorId: null,
        vendorMobile: null,
      };
    default:
      return state;
  }
};

export default vendorReducer;

// src/redux/reducers/rootReducer.js
import { combineReducers } from 'redux';
import authReducer from './authReducer';
import vendorReducer from './vendorReducer';

const rootReducer = combineReducers({
  auth: authReducer,
  vendor: vendorReducer,
});

export default rootReducer;

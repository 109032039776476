// src/redux/actions.js
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';

export const login = (userData) => {
  return {
    type: LOGIN,
    payload: {
      user: {
        _id: userData.user._id,
        email: userData.user.email,
        name: userData.user.name,
        role: userData.user.role,
        vendorId: userData.user.vendorId, // Include vendorId
        permissions: userData.user.permissions // Include permissions
      },
      token: userData.token,
    },
  };
};

export const logout = () => ({
  type: LOGOUT,
});

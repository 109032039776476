// src/redux/actions/vendorActions.js
export const SET_SELECTED_VENDOR = 'SET_SELECTED_VENDOR';
export const RESET_VENDOR = 'RESET_VENDOR'; // Add RESET_VENDOR action

export const setSelectedVendor = (vendorId, vendorMobile) => ({
  type: SET_SELECTED_VENDOR,
  payload: { vendorId, vendorMobile },
});

export const resetVendor = () => ({
  type: RESET_VENDOR, // Action to reset vendorId on logout
});

import useSettings from "app/hooks/useSettings";

export default function MatxLogo({ className }) {
  const { settings } = useSettings();
  const theme = settings.themes[settings.activeTheme];

  return (
    <img
      src="/assets/images/logos/logo.png"  // Replace this with the actual path to your image
      alt="Matx Logo"
      width="48px"
      height="48px"
      className={className}
    />
  );
}

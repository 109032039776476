import { LOGIN, LOGOUT } from '../actions/authActions';

const initialState = {
  user: JSON.parse(localStorage.getItem('user')) || null,
  token: localStorage.getItem('token') || null,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN:
      localStorage.setItem('user', JSON.stringify(action.payload.user));
      localStorage.setItem('token', action.payload.token);
      return { ...state, user: action.payload.user, token: action.payload.token };

    case LOGOUT:
      localStorage.removeItem('user');
      localStorage.removeItem('token');
      localStorage.removeItem('vendorId'); // Remove vendorId from localStorage
      return { ...state, user: null, token: null };

    default:
      return state;
  }
};

export default authReducer;

import React, { useEffect } from 'react';
import { Navigate, useLocation ,useParams} from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { jwtDecode } from 'jwt-decode';
import { login } from '../../redux/actions/authActions';
import { setSelectedVendor } from '../../redux/actions/vendorActions';

const AuthGuard = ({ children, requiredAction }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const location = useLocation();
  const { mode } = useParams();
  const mode_data = (mode === "edit" || mode === "create") ? mode : "view"; 
  // If the user is not in Redux state, check localStorage for the token

  // Effect to check for token on component mount
  useEffect(() => {
    const token = localStorage.getItem('token');

    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        dispatch(login({ user: decodedToken, token }));

        // If the token has vendor data, set it in the state
        if (decodedToken.vendor_id && decodedToken.vendor_mobile) {
          dispatch(setSelectedVendor(decodedToken.vendor_id, decodedToken.vendor_mobile));
        }
      } catch (e) {
        console.error('Invalid token:', e);
        // If token is invalid, redirect to signin
        return <Navigate to="/signin" state={{ from: location }} />;
      }
    }
  }, [dispatch, location]);

  // If user is not set, navigate to signin
  if (!user) {
    return <Navigate to="/signin" state={{ from: location }} />;
  }
  if (requiredAction) {

    const permissions = 
    { 
        Customers: [
            "view",
            "edit",
            "create"
        ],
        Category: [
            "view",
            "edit",
            "create"
        ],
        Products: [
            "view",
            "edit",
            "create"
        ],
        Cart: [
            "view",
            "edit"
        ],       
        Orders: [
            "view",
            "edit"
        ],
        Chat: [
            "view",
            "send"
        ],
        Chat_Template: [
          "view",
          "send"
        ],
        Vendors: [
          "view",
          "edit",
          "create"
        ]
    }
    // const updatedPermissions = [
    //   {
    //     section: "Customers",
    //     actions: user.permissions.find(permission => permission.section === "Customers")?.actions.filter(action => action !== "edit"  ) || []
    //   },
    //   ...user.permissions.filter(permission => permission.section !== "Customers")
    // ];
      
      const hasPermissions = (section, actions) => {
        const userPermission = user.permissions.find(permission => permission.section === section);
        if (userPermission) {
          // Check if each action is included in the user's actions
          const results = {};
          actions.forEach(action => {
            results[action] = userPermission.actions.includes(action);
          });
          return results; // Return an object with the results for each action
        }
        return actions.reduce((acc, action) => {
          acc[action] = false; // Default to false if no permissions found
          return acc;
        }, {});
      };
      
      const chatPermissions = hasPermissions(requiredAction.section, permissions[requiredAction.section]);

     if(mode_data=="edit" || mode_data=="create"){
      
      if(!chatPermissions.edit){
        console.warn(`Access denied for action: ${requiredAction.action} on section: ${requiredAction.section}`);
      
        return <Navigate to="/404" />;
      }    
  }
  if(mode_data=="view"){
    if(!chatPermissions.view){
      console.warn(`Access denied for action: ${requiredAction.action} on section: ${requiredAction.section}`);
    return <Navigate to="/404" />;
    }
   }
   if(mode_data=="create"){
    if(!chatPermissions.create){
      console.warn(`Access denied for action: ${requiredAction.action} on section: ${requiredAction.section}`);
    
      return <Navigate to="/404" />;
    }  
   }
};
return children;
}
export default AuthGuard;

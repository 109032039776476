import { lazy } from "react";
import { Navigate } from "react-router-dom";
import AuthGuard from "./auth/AuthGuard";
import { authRoles } from "./auth/authRoles";
import Loadable from "./components/Loadable";
import MatxLayout from "./components/MatxLayout/MatxLayout";
import { useRoutes } from "react-router-dom";

// Lazy-loaded components
const Home = Loadable(lazy(() => import("./views/dashboard/Analytics")));
const EditBroadcast = Loadable(lazy(() => import("./views/pages/broadcast/EditBroadcast")));
const SendBroadcast = Loadable(lazy(() => import("./views/pages/broadcast/SendBroadcast")));
const CustomerList = Loadable(lazy(() => import("./views/pages/customers/CustomersList")));
const ViewCustomer = Loadable(lazy(() => import("./views/pages/customers/ViewCustomer")));
const AddEditCustomer = Loadable(lazy(() => import("./views/pages/customers/AddEditCustomer")));
const ViewChat = Loadable(lazy(() => import("./views/pages/chat/ChatView")));
const ChatFlow = Loadable(lazy(() => import("./views/pages/chat/ChatFlow")));
const ChatbotFlowchart = Loadable(lazy(() => import("./views/pages/chat/ChatbotFlowchart")));
const CustomChat = Loadable(lazy(() => import("./views/pages/chat/CustomChatTemplate")));
const CategoryList = Loadable(lazy(() => import("./views/pages/categories/CategoryList")));
const CreateCategory = Loadable(lazy(() => import("./views/pages/categories/CategoryCreateForm")));
const ViewCategory = Loadable(lazy(() => import("./views/pages/categories/ViewCategory")));
const VendorsList = Loadable(lazy(() => import("./views/pages/vendors/VendorsList")));
const CreateVendor = Loadable(lazy(() => import("./views/pages/vendors/VendorCreateForm")));
const ProductsList = Loadable(lazy(() => import("./views/pages/products/ProductsList")));
const ViewProduct = Loadable(lazy(() => import("./views/pages/products/ViewProduct")));
const OrdersList = Loadable(lazy(() => import("./views/pages/orders/OrdersList")));
const ViewOrder = Loadable(lazy(() => import("./views/pages/orders/ViewOrder")));
const GroupList = Loadable(lazy(() => import("./views/pages/chat_notification/GroupList")));
const NotificationList = Loadable(lazy(() => import("./views/pages/chat_notification/NotificationList")));
const CreateProduct = Loadable(lazy(() => import("./views/pages/products/ProductCreateForm")));
const ViewProfile = Loadable(lazy(() => import("./views/pages/profile/ViewProfile")));
const EditProfile = Loadable(lazy(() => import("./views/pages/profile/EditProfile")));
const CartManage = Loadable(lazy(() => import("./views/pages/cart/CartManagement")));
const ViewCart = Loadable(lazy(() => import("./views/pages/cart/ViewCart")));
const AppEchart = Loadable(lazy(() => import("./views/charts/echarts/AppEchart")));
const NotFound = Loadable(lazy(() => import("./views/pages/auth/NotFound")));
const JwtLogin = Loadable(lazy(() => import("./views/pages/auth/JwtLogin")));
const HandleGoogleRedirect = Loadable(lazy(() => import("./views/pages/products/HandleGoogleRedirect")));
const RoutesComponent = () => {
const routes = [
  {
    element: (
      <AuthGuard>
        <MatxLayout />
      </AuthGuard>
    ),
    children: [
      { path: "/dashboard", element: <Home /> },
      { path: "/profile/view", element: <ViewProfile /> },
      { path: "/profile/edit", element: <EditProfile /> },
      { 
        path: "/customers/list",
        element: <AuthGuard 
          requiredAction={{ section: "Customers" }} 
        ><CustomerList /></AuthGuard> 
      },
      { 
        path: "/customer/:mode/:id?", 
        element: <AuthGuard 
        requiredAction={{ section: "Customers" }} 
        ><AddEditCustomer /></AuthGuard> 
      },
      { 
        path: "/customer/view/:id", 
        element: <AuthGuard 
        requiredAction={{ section: "Customers" }} 
        ><ViewCustomer /></AuthGuard> 
      },
      { 
        path: "/categories/list", 
        element: <AuthGuard 
        requiredAction={{ section: "Category" }} 
        ><CategoryList /></AuthGuard> 

      },
      { 
        path: "/category/:mode/:id?", 
        element: <AuthGuard 
        requiredAction={{ section: "Category" }} 
        ><CreateCategory /></AuthGuard> 
      },
      { 
        path: "/category/view/:id", 
        element: <AuthGuard 
        requiredAction={{ section: "Category" }}
        ><ViewCategory /></AuthGuard> 
      },
      { 
        path: "/vendors/list", 
        element: <AuthGuard 
        requiredAction={{ section: "Vendors" }} 
        ><VendorsList /></AuthGuard> 
      },
      { 
        path: "/vendor/:mode/:id?", 
        element: <AuthGuard 
        requiredAction={{ section: "Vendors"}} 
        ><CreateVendor /></AuthGuard> 
      },
      {
        path: "/products/list/:subcategory?",  // The '?' makes the subcategory parameter optional
        element: <AuthGuard requiredAction={{ section: "Products" }}><ProductsList /></AuthGuard>
      },
      { 
        path: "/product/:mode/:id?", 
        element: <AuthGuard 
        requiredAction={{ section: "Products"}} 
        ><CreateProduct /></AuthGuard> 
      },
      { 
        path: "/product/view/:id", 
        element: <AuthGuard 
        requiredAction={{ section: "Products" }} 
        ><ViewProduct /></AuthGuard> 
      },
      { 
        path: "/orders/list", 
        element: <AuthGuard 
        requiredAction={{ section: "Orders" }} 
        ><OrdersList /></AuthGuard> 
      },
      { 
        path: "/order/view/:id", 
        element: <AuthGuard 
        requiredAction={{ section: "Orders" }} 
        ><ViewOrder /></AuthGuard> 
      },
      { 
        path: "/cart/manage", 
        element: <AuthGuard 
        requiredAction={{ section: "Cart" }} 
        ><CartManage /></AuthGuard> 
      },
      { 
        path: "/cart/view/:id", 
        element: <AuthGuard 
        requiredAction={{ section: "Cart" }} 
        ><ViewCart /></AuthGuard> 
      },
      { 
        path: "/chat/view", 
        element: <AuthGuard 
        requiredAction={{ section: "Chat" }} 
        ><ViewChat /></AuthGuard> 
      },
      { 
        path: "/chat/template", 
        element: <AuthGuard 
        requiredAction={{ section: "Chat_Template" }} 
        ><CustomChat /></AuthGuard> 
      },
      { 
        path: "/chat/flow", 
        element: <AuthGuard 
        ><ChatFlow /></AuthGuard> 
      },
      { 
        path: "/chat/flowchart", 
        element: <AuthGuard
        ><ChatbotFlowchart /></AuthGuard> 
      },
      { 
        path: "/broadcast/edit", 
        element: <AuthGuard
        ><EditBroadcast /></AuthGuard> 
      },
      { 
        path: "/broadcast/send", 
        element: <AuthGuard
        ><SendBroadcast /></AuthGuard> 
      },
      {
        path: "/dashboard/default",
        element: <AuthGuard 
        ><Home /></AuthGuard>,
      },
      {
        path: "/charts/echarts",
        element: <AuthGuard auth={[authRoles.editor]}><AppEchart /></AuthGuard>,
      },
      { 
        path: "/group/list", 
        element: <AuthGuard auth={[authRoles.ADMIN, authRoles.VENDOR]}><GroupList /></AuthGuard> 
      },
      { 
        path: "/notification/list", 
        element: <AuthGuard auth={[authRoles.ADMIN, authRoles.VENDOR]}><NotificationList /></AuthGuard> 
      },
    ],
  },
  { path: "/404", element: <NotFound /> },
  { path: "/signin", element: <JwtLogin /> },
  { path: "/", element: <Navigate to="/dashboard" /> },
  { path: "*", element: <Navigate to="/dashboard" /> },
  { path: "/oauth2callback", element: <HandleGoogleRedirect /> },
];
return useRoutes(routes); // Returns the routes for rendering

}
export default RoutesComponent;
